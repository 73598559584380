import React, { useEffect, useRef } from 'react';

const Record = () => {
  const svgRef = useRef(null);

  useEffect(() => {
    const svgElement = svgRef.current;
    let rotate = 0;
    const rotateSvg = () => {
      rotate = (rotate + 0.5) % 360; // Adjust the speed by changing the increment value
      svgElement.style.transform = `rotate(${rotate}deg)`;
      requestAnimationFrame(rotateSvg);
    };

    rotateSvg(); // Start the rotation animation

    return () => {
      // Cleanup function
      cancelAnimationFrame(rotateSvg);
    };
  }, []);

  return (
    <svg
      ref={svgRef}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1782_2268)">
        <mask id="path-1-inside-1_1782_2268" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 16.8C14.651 16.8 16.8 14.651 16.8 12C16.8 9.34903 14.651 7.2 12 7.2C9.34903 7.2 7.2 9.34903 7.2 12C7.2 14.651 9.34903 16.8 12 16.8ZM17.4 7.2C17.7314 7.2 18 6.93137 18 6.6C18 6.26863 17.7314 6 17.4 6C17.0686 6 16.8 6.26863 16.8 6.6C16.8 6.93137 17.0686 7.2 17.4 7.2ZM6.6 18C6.93137 18 7.2 17.7314 7.2 17.4C7.2 17.0686 6.93137 16.8 6.6 16.8C6.26863 16.8 6 17.0686 6 17.4C6 17.7314 6.26863 18 6.6 18ZM12 13.2C12.6627 13.2 13.2 12.6627 13.2 12C13.2 11.3373 12.6627 10.8 12 10.8C11.3373 10.8 10.8 11.3373 10.8 12C10.8 12.6627 11.3373 13.2 12 13.2Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12 16.8C14.651 16.8 16.8 14.651 16.8 12C16.8 9.34903 14.651 7.2 12 7.2C9.34903 7.2 7.2 9.34903 7.2 12C7.2 14.651 9.34903 16.8 12 16.8ZM17.4 7.2C17.7314 7.2 18 6.93137 18 6.6C18 6.26863 17.7314 6 17.4 6C17.0686 6 16.8 6.26863 16.8 6.6C16.8 6.93137 17.0686 7.2 17.4 7.2ZM6.6 18C6.93137 18 7.2 17.7314 7.2 17.4C7.2 17.0686 6.93137 16.8 6.6 16.8C6.26863 16.8 6 17.0686 6 17.4C6 17.7314 6.26863 18 6.6 18ZM12 13.2C12.6627 13.2 13.2 12.6627 13.2 12C13.2 11.3373 12.6627 10.8 12 10.8C11.3373 10.8 10.8 11.3373 10.8 12C10.8 12.6627 11.3373 13.2 12 13.2Z"
          fill="#404040"
        />
        <path
          d="M23 12C23 18.0751 18.0751 23 12 23V25C19.1797 25 25 19.1797 25 12H23ZM12 1C18.0751 1 23 5.92487 23 12H25C25 4.8203 19.1797 -1 12 -1V1ZM1 12C1 5.92487 5.92487 1 12 1V-1C4.8203 -1 -1 4.8203 -1 12H1ZM12 23C5.92487 23 1 18.0751 1 12H-1C-1 19.1797 4.8203 25 12 25V23ZM15.8 12C15.8 14.0987 14.0987 15.8 12 15.8V17.8C15.2033 17.8 17.8 15.2033 17.8 12H15.8ZM12 8.2C14.0987 8.2 15.8 9.90132 15.8 12H17.8C17.8 8.79675 15.2033 6.2 12 6.2V8.2ZM8.2 12C8.2 9.90132 9.90132 8.2 12 8.2V6.2C8.79675 6.2 6.2 8.79675 6.2 12H8.2ZM12 15.8C9.90132 15.8 8.2 14.0987 8.2 12H6.2C6.2 15.2033 8.79675 17.8 12 17.8V15.8ZM17 6.6C17 6.37909 17.1791 6.2 17.4 6.2V8.2C18.2837 8.2 19 7.48365 19 6.6H17ZM17.4 7C17.1791 7 17 6.82091 17 6.6H19C19 5.71635 18.2837 5 17.4 5V7ZM17.8 6.6C17.8 6.82092 17.6209 7 17.4 7V5C16.5164 5 15.8 5.71634 15.8 6.6H17.8ZM17.4 6.2C17.6209 6.2 17.8 6.37908 17.8 6.6H15.8C15.8 7.48366 16.5164 8.2 17.4 8.2V6.2ZM6.2 17.4C6.2 17.1791 6.37909 17 6.6 17V19C7.48365 19 8.2 18.2837 8.2 17.4H6.2ZM6.6 17.8C6.37908 17.8 6.2 17.6209 6.2 17.4H8.2C8.2 16.5164 7.48366 15.8 6.6 15.8V17.8ZM7 17.4C7 17.6209 6.82092 17.8 6.6 17.8V15.8C5.71634 15.8 5 16.5164 5 17.4H7ZM6.6 17C6.82091 17 7 17.1791 7 17.4H5C5 18.2837 5.71635 19 6.6 19V17ZM12.2 12C12.2 12.1105 12.1105 12.2 12 12.2V14.2C13.215 14.2 14.2 13.215 14.2 12H12.2ZM12 11.8C12.1105 11.8 12.2 11.8895 12.2 12H14.2C14.2 10.785 13.215 9.8 12 9.8V11.8ZM11.8 12C11.8 11.8895 11.8895 11.8 12 11.8V9.8C10.785 9.8 9.8 10.785 9.8 12H11.8ZM12 12.2C11.8895 12.2 11.8 12.1105 11.8 12H9.8C9.8 13.215 10.785 14.2 12 14.2V12.2Z"
          fill="#404040"
          mask="url(#path-1-inside-1_1782_2268)"
        />
        <circle cx="12" cy="12" r="11.5" stroke="#FCD34D" />
      </g>
      <defs>
        <clipPath id="clip0_1782_2268">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Record;
