import React from 'react';

export default function Skip({ fill = '#404040' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path d="M6 18L14.5 12L6 6V18ZM16 6V18H18V6H16Z" fill={fill} />
    </svg>
  );
}
