import React from 'react';

const NotificationActive = ({ fill = '#404040' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9164 2.81143C12.6632 2.61607 12.3455 2.5 12 2.5C11.17 2.5 10.5 3.17 10.5 4V4.68C7.63 5.36 6 7.92 6 11L6 16L4 18V19L20 19V18L18 16V12C14.6863 12 12 9.31371 12 6C12 4.82838 12.3358 3.73519 12.9164 2.81143ZM14 20C14 21.1 13.1 22 12 22C10.89 22 10 21.1 10 20H14Z"
      fill={fill}
    />
    <circle cx="18" cy="6" r="3.5" fill="#FCD34D" stroke="#404040" />
  </svg>
);

export default NotificationActive;
