import React from 'react';
const Asterisk = ({ fill = '#404040' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.9489 18L11.1241 13.4167L7.0511 15.875L6 14.125L10.292 12L6 9.875L7.0511 8.125L11.1241 10.5833L10.9489 6H13.0511L12.8759 10.5833L16.9489 8.125L18 9.875L13.708 12L18 14.125L16.9489 15.875L12.8759 13.4167L13.0511 18H10.9489Z"
        fill={fill}
      />
    </svg>
  );
};

export default Asterisk;
