// NavBar.js
import React from 'react';
import styles from './SongPage.module.scss';
import Close from '../Icons/Close.js';
import Skip from '../Icons/Skip.js';
import Spinner from '../Spinner/Spinner';

const NavBar = ({
  songDetails,
  navbarState,
  onClose,
  onSkip,
  isLoadingNextSong,
}) => {
  return (
    <div className={styles.navBar}>
      <div className={styles.navBarTitle}>
        <div className={`${styles.navBarTitleContent} ${styles[navbarState]}`}>
          <div className={styles.defaultTitle}>A Good Song</div>
          <div className={styles.scrolledTitle}>
            <div className={styles.navBarSong}>{songDetails?.songName}</div>
            <div className={styles.navBarArtist}>{songDetails?.songArtist}</div>
          </div>
        </div>
      </div>
      <div className={styles.navBarButtons}>
        <div
          onClick={!isLoadingNextSong ? onSkip : null}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && !isLoadingNextSong && onSkip()}
          className={styles.skipButton}
        >
          {isLoadingNextSong ? (
            <Spinner size="small" />
          ) : (
            <Skip fill="#404040" />
          )}
        </div>
        <div
          onClick={onClose}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => e.key === 'Enter' && onClose()}
          className={styles.closeButton}
        >
          <Close fill="#404040" />
        </div>
      </div>
    </div>
  );
};

export default NavBar;
