import React from 'react';

const Logo = ({ fill = '#404040' }) => (
  <svg
    width="56"
    height="216"
    viewBox="0 0 56 216"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.4409 180.107L39.7539 180.107L39.7539 182.218L32.9938 182.218L32.9938 182.397C33.2323 182.552 33.5363 182.766 33.9059 183.04C34.2815 183.321 34.6153 183.72 34.9074 184.239C35.2055 184.763 35.3545 185.473 35.3545 186.367C35.3545 187.523 35.0654 188.543 34.4871 189.425C33.9089 190.307 33.0892 190.996 32.0281 191.49C30.967 191.985 29.7151 192.233 28.2725 192.233C26.8179 192.233 25.5571 191.985 24.4901 191.49C23.4289 190.996 22.6063 190.31 22.0221 189.434C21.4438 188.558 21.1547 187.547 21.1547 186.403C21.1547 185.52 21.3008 184.814 21.5929 184.283C21.8909 183.753 22.2277 183.344 22.6033 183.058C22.9848 182.772 23.3008 182.552 23.5512 182.397L23.5512 182.146L21.4409 182.146L21.4409 180.107ZM28.3083 182.182C27.271 182.182 26.3559 182.334 25.5631 182.638C24.7762 182.942 24.1592 183.386 23.7121 183.97C23.271 184.555 23.0504 185.27 23.0504 186.116C23.0504 186.999 23.2829 187.735 23.7479 188.325C24.2188 188.921 24.8507 189.368 25.6436 189.666C26.4424 189.97 27.3306 190.122 28.3083 190.122C29.274 190.122 30.1443 189.973 30.9193 189.675C31.7002 189.383 32.3172 188.939 32.7703 188.343C33.2293 187.753 33.4588 187.011 33.4588 186.116C33.4588 185.258 33.2412 184.537 32.806 183.952C32.3768 183.368 31.7747 182.927 30.9998 182.629C30.2308 182.331 29.3336 182.182 28.3083 182.182Z"
      fill={fill}
    />
    <path
      d="M27.0565 175.861L35.1758 175.861L35.1758 177.971L21.441 177.971L21.441 175.861L23.7659 175.861L23.7659 175.718C23.0684 175.396 22.4753 174.895 21.9865 174.216C21.5036 173.536 21.2622 172.678 21.2622 171.64C21.2622 170.782 21.4499 170.019 21.8255 169.351C22.207 168.683 22.7793 168.159 23.5423 167.777C24.3114 167.396 25.2801 167.205 26.4485 167.205L35.1758 167.205L35.1758 169.315L26.5915 169.315C25.59 169.315 24.7912 169.596 24.1951 170.156C23.599 170.722 23.3009 171.444 23.3009 172.32C23.3009 172.844 23.435 173.378 23.7033 173.92C23.9716 174.469 24.3829 174.928 24.9373 175.298C25.4917 175.673 26.1981 175.861 27.0565 175.861Z"
      fill={fill}
    />
    <path
      d="M39.7539 165.356L21.4409 165.356L21.4409 163.246L39.7539 163.246L39.7539 165.356Z"
      fill={fill}
    />
    <path
      d="M34.0307 161.96L34.0307 159.743C34.6686 159.612 35.2289 159.382 35.7118 159.054C36.1946 158.732 36.6 158.339 36.9279 157.874C37.2617 157.415 37.5121 156.905 37.679 156.345C37.8459 155.785 37.9294 155.2 37.9294 154.592C37.9294 153.483 37.6492 152.479 37.0888 151.579C36.5285 150.685 35.7028 149.972 34.6119 149.442C33.521 148.917 32.1827 148.655 30.597 148.655C29.0113 148.655 27.673 148.917 26.5821 149.442C25.4912 149.972 24.6655 150.685 24.1052 151.579C23.5448 152.479 23.2646 153.483 23.2646 154.592C23.2646 155.2 23.3481 155.785 23.515 156.345C23.6819 156.905 23.9293 157.415 24.2572 157.874C24.591 158.339 24.9994 158.732 25.4822 159.054C25.9711 159.382 26.5314 159.612 27.1633 159.743L27.1633 161.96C26.2274 161.794 25.3898 161.489 24.6506 161.048C23.9114 160.607 23.2825 160.059 22.7639 159.403C22.2512 158.747 21.8608 158.011 21.5925 157.194C21.3242 156.384 21.1901 155.516 21.1901 154.592C21.1901 153.03 21.5716 151.641 22.3347 150.425C23.0977 149.209 24.1827 148.252 25.5895 147.555C26.9964 146.858 28.6656 146.509 30.597 146.509C32.5285 146.509 34.1976 146.858 35.6045 147.555C37.0113 148.252 38.0963 149.209 38.8593 150.425C39.6224 151.641 40.0039 153.03 40.0039 154.592C40.0039 155.516 39.8698 156.384 39.6015 157.194C39.3333 158.011 38.9398 158.747 38.4212 159.403C37.9085 160.059 37.2826 160.607 36.5434 161.048C35.8101 161.489 34.9726 161.794 34.0307 161.96Z"
      fill={fill}
    />
    <path
      d="M16.0013 134.086C16.0013 133.067 16.1324 132.191 16.3947 131.457C16.651 130.724 16.9908 130.113 17.4141 129.624C17.8314 129.141 18.2785 128.757 18.7554 128.471L19.9357 130.152C19.6853 130.343 19.3992 130.584 19.0773 130.876C18.7494 131.168 18.4662 131.568 18.2278 132.074C17.9834 132.587 17.8612 133.258 17.8612 134.086C17.8612 135.195 18.1294 136.11 18.6659 136.831C19.2025 137.553 20.043 137.913 21.1876 137.913L23.9774 137.913L23.9774 137.735C23.7271 137.58 23.4171 137.359 23.0475 137.073C22.6838 136.793 22.359 136.387 22.0728 135.857C21.7926 135.332 21.6525 134.623 21.6525 133.729C21.6525 132.62 21.9148 131.624 22.4394 130.742C22.964 129.866 23.7271 129.171 24.7286 128.659C25.7301 128.152 26.9462 127.898 28.3769 127.898C29.7837 127.898 31.0088 128.146 32.052 128.641C33.1012 129.135 33.9119 129.824 34.4842 130.706C35.0624 131.588 35.3516 132.608 35.3516 133.764C35.3516 134.659 35.2025 135.368 34.9045 135.893C34.6124 136.423 34.2785 136.828 33.903 137.109C33.5334 137.395 33.2293 137.615 32.9909 137.77L32.9909 137.985L35.1727 137.985L35.1727 140.024L21.0445 140.024C19.8642 140.024 18.9044 139.755 18.1652 139.219C17.42 138.688 16.8746 137.973 16.5288 137.073C16.1771 136.179 16.0013 135.183 16.0013 134.086ZM23.5482 134.015C23.5482 134.861 23.742 135.577 24.1295 136.161C24.5169 136.745 25.0743 137.189 25.8016 137.493C26.5289 137.797 27.3992 137.949 28.4126 137.949C29.4022 137.949 30.2755 137.8 31.0326 137.502C31.7897 137.204 32.3828 136.763 32.8121 136.179C33.2413 135.594 33.4559 134.873 33.4559 134.015C33.4559 133.121 33.2293 132.375 32.7763 131.779C32.3232 131.189 31.7152 130.745 30.9521 130.447C30.1891 130.155 29.3426 130.009 28.4126 130.009C27.4588 130.009 26.6153 130.158 25.8821 130.456C25.1548 130.76 24.5825 131.207 24.1652 131.797C23.7539 132.393 23.5482 133.132 23.5482 134.015Z"
      fill={fill}
    />
    <path
      d="M29.7003 117.938L21.4379 117.938L21.4379 115.828L35.1727 115.828L35.1727 117.867L33.0267 117.867L33.0267 118.046C33.7241 118.368 34.2845 118.856 34.7077 119.512C35.137 120.168 35.3516 121.014 35.3516 122.052C35.3516 122.982 35.1608 123.795 34.7793 124.493C34.4037 125.19 33.8314 125.733 33.0624 126.12C32.2994 126.508 31.3337 126.701 30.1653 126.701L21.4379 126.701L21.4379 124.591L30.0222 124.591C31.1012 124.591 31.9417 124.311 32.5438 123.751C33.1519 123.19 33.4559 122.421 33.4559 121.444C33.4559 120.77 33.3098 120.168 33.0177 119.637C32.7256 119.113 32.2994 118.698 31.739 118.394C31.1787 118.09 30.4991 117.938 29.7003 117.938Z"
      fill={fill}
    />
    <path
      d="M21.1518 108.396C21.1518 107.156 21.4469 106.069 22.037 105.133C22.6272 104.203 23.4529 103.475 24.514 102.951C25.5751 102.432 26.815 102.173 28.2338 102.173C29.6645 102.173 30.9134 102.432 31.9805 102.951C33.0475 103.475 33.8761 104.203 34.4663 105.133C35.0565 106.069 35.3516 107.156 35.3516 108.396C35.3516 109.636 35.0565 110.721 34.4663 111.651C33.8761 112.587 33.0475 113.314 31.9805 113.833C30.9134 114.358 29.6645 114.62 28.2338 114.62C26.815 114.62 25.5751 114.358 24.514 113.833C23.4529 113.314 22.6272 112.587 22.037 111.651C21.4469 110.721 21.1518 109.636 21.1518 108.396ZM23.0475 108.396C23.0475 109.338 23.2889 110.113 23.7718 110.721C24.2546 111.329 24.8895 111.779 25.6764 112.072C26.4633 112.364 27.3158 112.51 28.2338 112.51C29.1518 112.51 30.0073 112.364 30.8001 112.072C31.593 111.779 32.2338 111.329 32.7226 110.721C33.2115 110.113 33.4559 109.338 33.4559 108.396C33.4559 107.455 33.2115 106.68 32.7226 106.072C32.2338 105.463 31.593 105.013 30.8001 104.721C30.0073 104.429 29.1518 104.283 28.2338 104.283C27.3158 104.283 26.4633 104.429 25.6764 104.721C24.8895 105.013 24.2546 105.463 23.7718 106.072C23.2889 106.68 23.0475 107.455 23.0475 108.396Z"
      fill={fill}
    />
    <path
      d="M35.1753 99.0723C36.0814 98.965 36.7848 98.5299 37.2856 97.7668C37.7863 97.0038 38.0367 96.0679 38.0367 94.9591C38.0367 94.1483 37.9055 93.4389 37.6432 92.8309C37.3809 92.2288 37.0203 91.7579 36.5613 91.4181C36.1022 91.0842 35.5806 90.9173 34.9964 90.9173C34.5076 90.9173 34.0873 91.0336 33.7356 91.2661C33.3899 91.5045 33.1007 91.8085 32.8683 92.1781C32.6417 92.5477 32.4539 92.9352 32.3049 93.3406C32.1618 93.7459 32.0456 94.1185 31.9562 94.4583L31.4554 96.3182C31.3302 96.7951 31.1574 97.3257 30.9368 97.9099C30.7162 98.5001 30.4152 99.0634 30.0337 99.5999C29.6581 100.142 29.1752 100.589 28.5851 100.941C27.9949 101.293 27.2706 101.469 26.4122 101.469C25.4226 101.469 24.5284 101.209 23.7296 100.691C22.9308 100.178 22.2959 99.427 21.825 98.4375C21.354 97.4539 21.1186 96.2586 21.1186 94.8518C21.1186 93.5403 21.3302 92.4047 21.7535 91.4449C22.1767 90.4911 22.7669 89.74 23.524 89.1915C24.281 88.649 25.1603 88.342 26.1618 88.2705L26.1618 90.5596C25.4703 90.6193 24.898 90.8517 24.445 91.2571C23.9979 91.6684 23.664 92.1871 23.4435 92.813C23.2289 93.4449 23.1216 94.1245 23.1216 94.8518C23.1216 95.6983 23.2587 96.4583 23.5329 97.1319C23.8131 97.8056 24.2006 98.3391 24.6953 98.7326C25.1961 99.126 25.7803 99.3227 26.448 99.3227C27.056 99.3227 27.5508 99.1528 27.9323 98.813C28.3138 98.4732 28.6238 98.0261 28.8623 97.4717C29.1007 96.9173 29.3094 96.3182 29.4882 95.6744L30.132 93.4211C30.5434 91.9903 31.1305 90.8577 31.8936 90.0231C32.6566 89.1885 33.6551 88.7713 34.8891 88.7713C35.9145 88.7713 36.8087 89.0485 37.5717 89.6029C38.3407 90.1632 38.9368 90.9143 39.3601 91.8562C39.7893 92.8041 40.0039 93.8622 40.0039 95.0306C40.0039 96.2109 39.7923 97.2601 39.369 98.1781C38.9517 99.0962 38.3795 99.8235 37.6522 100.36C36.9249 100.902 36.0993 101.189 35.1753 101.218L35.1753 99.0723Z"
      fill={fill}
    />
    <path
      d="M21.1547 80.334C21.1547 79.1895 21.4438 78.179 22.0221 77.3027C22.6063 76.4264 23.4289 75.7409 24.4901 75.2461C25.5571 74.7513 26.8179 74.5039 28.2725 74.5039C29.7151 74.5039 30.967 74.7513 32.0281 75.2461C33.0892 75.7409 33.9089 76.4294 34.4871 77.3117C35.0654 78.1939 35.3545 79.2133 35.3545 80.3698C35.3545 81.264 35.2055 81.9704 34.9074 82.489C34.6153 83.0136 34.2815 83.413 33.9059 83.6873C33.5363 83.9674 33.2323 84.185 32.9938 84.34L32.9938 84.5189L39.7539 84.5189L39.7539 86.6291L21.4409 86.6291L21.4409 84.5904L23.5512 84.5904L23.5512 84.34C23.3008 84.185 22.9848 83.9645 22.6033 83.6783C22.2277 83.3922 21.8909 82.9838 21.5929 82.4533C21.3008 81.9227 21.1547 81.2163 21.1547 80.334ZM23.0504 80.6202C23.0504 81.4667 23.271 82.182 23.7121 82.7662C24.1592 83.3504 24.7762 83.7946 25.5631 84.0986C26.3559 84.4026 27.271 84.5546 28.3083 84.5546C29.3336 84.5546 30.2308 84.4056 30.9998 84.1075C31.7747 83.8095 32.3768 83.3683 32.806 82.7841C33.2412 82.1999 33.4588 81.4786 33.4588 80.6202C33.4588 79.726 33.2293 78.9808 32.7703 78.3847C32.3172 77.7945 31.7002 77.3504 30.9193 77.0524C30.1443 76.7603 29.274 76.6142 28.3083 76.6142C27.3306 76.6142 26.4424 76.7632 25.6436 77.0613C24.8507 77.3653 24.2188 77.8124 23.7479 78.4026C23.2829 78.9987 23.0504 79.7379 23.0504 80.6202Z"
      fill={fill}
    />
    <path
      d="M21.1518 67.7197C21.1518 66.4797 21.4469 65.3918 22.037 64.4559C22.6272 63.5259 23.4529 62.7986 24.514 62.274C25.5751 61.7554 26.815 61.4961 28.2338 61.4961C29.6645 61.4961 30.9134 61.7554 31.9805 62.274C33.0475 62.7986 33.8761 63.5259 34.4663 64.4559C35.0565 65.3918 35.3516 66.4797 35.3516 67.7197C35.3516 68.9596 35.0565 70.0446 34.4663 70.9745C33.8761 71.9104 33.0475 72.6377 31.9805 73.1564C30.9134 73.6809 29.6645 73.9432 28.2338 73.9432C26.815 73.9432 25.5751 73.6809 24.514 73.1564C23.4529 72.6377 22.6272 71.9104 22.037 70.9745C21.4469 70.0446 21.1518 68.9596 21.1518 67.7197ZM23.0475 67.7197C23.0475 68.6616 23.2889 69.4365 23.7718 70.0446C24.2546 70.6526 24.8895 71.1027 25.6764 71.3948C26.4633 71.6869 27.3158 71.833 28.2338 71.833C29.1518 71.833 30.0073 71.6869 30.8001 71.3948C31.593 71.1027 32.2338 70.6526 32.7226 70.0446C33.2115 69.4365 33.4559 68.6616 33.4559 67.7197C33.4559 66.7778 33.2115 66.0028 32.7226 65.3948C32.2338 64.7867 31.593 64.3366 30.8001 64.0445C30.0073 63.7524 29.1518 63.6064 28.2338 63.6064C27.3158 63.6064 26.4633 63.7524 25.6764 64.0445C24.8895 64.3366 24.2546 64.7867 23.7718 65.3948C23.2889 66.0028 23.0475 66.7778 23.0475 67.7197Z"
      fill={fill}
    />
    <path
      d="M21.1518 54.707C21.1518 53.467 21.4469 52.3791 22.037 51.4432C22.6272 50.5132 23.4529 49.7859 24.514 49.2613C25.5751 48.7427 26.815 48.4834 28.2338 48.4834C29.6645 48.4834 30.9134 48.7427 31.9805 49.2613C33.0475 49.7859 33.8761 50.5132 34.4663 51.4432C35.0565 52.3791 35.3516 53.467 35.3516 54.707C35.3516 55.9469 35.0565 57.0319 34.4663 57.9618C33.8761 58.8978 33.0475 59.625 31.9805 60.1437C30.9134 60.6683 29.6645 60.9305 28.2338 60.9305C26.815 60.9305 25.5751 60.6683 24.514 60.1437C23.4529 59.625 22.6272 58.8978 22.037 57.9618C21.4469 57.0319 21.1518 55.9469 21.1518 54.707ZM23.0475 54.707C23.0475 55.6489 23.2889 56.4238 23.7718 57.0319C24.2546 57.6399 24.8895 58.09 25.6764 58.3821C26.4633 58.6742 27.3158 58.8203 28.2338 58.8203C29.1518 58.8203 30.0073 58.6742 30.8001 58.3821C31.593 58.09 32.2338 57.6399 32.7226 57.0319C33.2115 56.4238 33.4559 55.6489 33.4559 54.707C33.4559 53.7651 33.2115 52.9901 32.7226 52.3821C32.2338 51.774 31.593 51.3239 30.8001 51.0318C30.0073 50.7397 29.1518 50.5937 28.2338 50.5937C27.3158 50.5937 26.4633 50.7397 25.6764 51.0318C24.8895 51.3239 24.2546 51.774 23.7718 52.3821C23.2889 52.9901 23.0475 53.7651 23.0475 54.707Z"
      fill={fill}
    />
    <path
      d="M34.0307 45.234C34.6328 45.0373 35.1723 44.778 35.6492 44.4561C36.1321 44.1401 36.5434 43.7616 36.8832 43.3205C37.223 42.8853 37.4823 42.3905 37.6611 41.8361C37.84 41.2817 37.9294 40.6737 37.9294 40.012C37.9294 38.927 37.6492 37.9404 37.0888 37.0522C36.5285 36.164 35.7028 35.4575 34.6119 34.9329C33.521 34.4084 32.1827 34.1461 30.597 34.1461C29.0113 34.1461 27.673 34.4113 26.5821 34.9419C25.4912 35.4724 24.6655 36.1908 24.1052 37.0969C23.5448 38.003 23.2646 39.0224 23.2646 40.155C23.2646 41.2042 23.4882 42.1282 23.9353 42.927C24.3883 43.7318 25.0262 44.3577 25.8489 44.8048C26.6775 45.2579 27.6521 45.4844 28.7729 45.4844L28.6298 46.164L28.6298 40.6558L30.597 40.6558L30.597 47.6305L28.6298 47.6305C27.1216 47.6305 25.8101 47.3086 24.6953 46.6647C23.5806 46.0269 22.7162 45.1446 22.1022 44.0179C21.4941 42.8972 21.1901 41.6096 21.1901 40.155C21.1901 38.5336 21.5716 37.1088 22.3347 35.8808C23.0977 34.6587 24.1827 33.7049 25.5895 33.0194C26.9964 32.3398 28.6656 32 30.597 32C32.0456 32 33.3481 32.1937 34.5046 32.5812C35.6671 32.9747 36.6566 33.5291 37.4733 34.2444C38.29 34.9598 38.916 35.8063 39.3511 36.7839C39.7863 37.7616 40.0039 38.8376 40.0039 40.012C40.0039 40.9777 39.8579 41.8778 39.5658 42.7124C39.2796 43.553 38.8713 44.3011 38.3407 44.9568C37.8161 45.6185 37.1872 46.17 36.454 46.6111C35.7267 47.0522 34.9189 47.3563 34.0307 47.5232L34.0307 45.234Z"
      fill={fill}
    />
    <path
      d="M17.1404 143.214L17.1409 143.213L17.1474 143.205L17.1475 143.205C18.4896 141.575 19.5699 140.266 20.5791 139.357C21.5842 138.453 22.4482 138.013 23.3475 138.013C24.7882 138.013 25.9922 139.266 25.9922 140.975C25.9922 141.936 25.5835 142.854 24.9552 143.443L24.5662 143.808L24.9552 144.173C25.5835 144.762 25.9922 145.68 25.9922 146.641C25.9922 148.35 24.7882 149.603 23.3475 149.603C22.4498 149.603 21.5872 149.164 20.5835 148.263C19.5756 147.357 18.4964 146.052 17.1554 144.427L17.1359 144.403L17.135 144.402L16.6481 143.809L17.1404 143.214Z"
      fill="#FCD34D"
      stroke={fill}
    />
  </svg>
);

export default Logo;
