import React from 'react';

const LogoYellow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="162"
    height="25"
    viewBox="0 0 162 25"
    fill="none"
  >
    <path
      d="M148.992 18.6783V0.365234H151.102V7.12532H151.281C151.436 6.88687 151.651 6.58285 151.925 6.21325C152.205 5.83769 152.605 5.50386 153.123 5.21175C153.648 4.91369 154.357 4.76466 155.252 4.76466C156.408 4.76466 157.427 5.05378 158.31 5.63202C159.192 6.21027 159.88 7.02994 160.375 8.09105C160.87 9.15216 161.117 10.404 161.117 11.8467C161.117 13.3012 160.87 14.562 160.375 15.6291C159.88 16.6902 159.195 17.5129 158.319 18.0971C157.442 18.6753 156.432 18.9644 155.287 18.9644C154.405 18.9644 153.699 18.8184 153.168 18.5263C152.638 18.2282 152.229 17.8914 151.943 17.5158C151.657 17.1343 151.436 16.8184 151.281 16.568H151.031V18.6783H148.992ZM151.067 11.8109C151.067 12.8482 151.219 13.7632 151.523 14.5561C151.827 15.3429 152.271 15.9599 152.855 16.407C153.439 16.8482 154.155 17.0687 155.001 17.0687C155.883 17.0687 156.62 16.8362 157.21 16.3713C157.806 15.9003 158.253 15.2684 158.551 14.4756C158.855 13.6768 159.007 12.7885 159.007 11.8109C159.007 10.8452 158.858 9.97482 158.56 9.19985C158.268 8.41892 157.824 7.80193 157.228 7.34887C156.638 6.88985 155.895 6.66034 155.001 6.66034C154.143 6.66034 153.421 6.87793 152.837 7.3131C152.253 7.74232 151.812 8.34441 151.514 9.11937C151.216 9.88838 151.067 10.7855 151.067 11.8109Z"
      fill="#FCD34D"
    />
    <path
      d="M144.746 13.0626V4.94336H146.856V18.6781H144.746V16.3532H144.603C144.281 17.0507 143.78 17.6439 143.1 18.1327C142.421 18.6156 141.562 18.857 140.525 18.857C139.667 18.857 138.904 18.6692 138.236 18.2936C137.568 17.9121 137.044 17.3398 136.662 16.5768C136.281 15.8078 136.09 14.8391 136.09 13.6707V4.94336H138.2V13.5276C138.2 14.5291 138.48 15.3279 139.041 15.924C139.607 16.5202 140.328 16.8182 141.205 16.8182C141.729 16.8182 142.263 16.6841 142.805 16.4158C143.354 16.1476 143.813 15.7363 144.182 15.1819C144.558 14.6275 144.746 13.921 144.746 13.0626Z"
      fill="#FCD34D"
    />
    <path
      d="M134.241 0.365234V18.6783H132.131V0.365234H134.241Z"
      fill="#FCD34D"
    />
    <path
      d="M130.844 6.08844H128.627C128.495 5.45058 128.266 4.89022 127.938 4.40735C127.616 3.92449 127.223 3.51912 126.758 3.19125C126.299 2.85742 125.789 2.60705 125.229 2.44013C124.668 2.27322 124.084 2.18976 123.476 2.18976C122.367 2.18976 121.363 2.46994 120.463 3.0303C119.568 3.59066 118.856 4.4163 118.326 5.50721C117.801 6.59812 117.539 7.93643 117.539 9.52213C117.539 11.1078 117.801 12.4461 118.326 13.5371C118.856 14.628 119.568 15.4536 120.463 16.014C121.363 16.5743 122.367 16.8545 123.476 16.8545C124.084 16.8545 124.668 16.771 125.229 16.6041C125.789 16.4372 126.299 16.1898 126.758 15.8619C127.223 15.5281 127.616 15.1198 127.938 14.6369C128.266 14.1481 128.495 13.5877 128.627 12.9558H130.844C130.677 13.8917 130.373 14.7293 129.932 15.4685C129.491 16.2077 128.943 16.8366 128.287 17.3553C127.631 17.8679 126.895 18.2584 126.078 18.5266C125.267 18.7949 124.4 18.929 123.476 18.929C121.914 18.929 120.525 18.5475 119.309 17.7845C118.093 17.0214 117.136 15.9365 116.439 14.5296C115.741 13.1227 115.393 11.4536 115.393 9.52213C115.393 7.59068 115.741 5.92152 116.439 4.51466C117.136 3.1078 118.093 2.02284 119.309 1.2598C120.525 0.496756 121.914 0.115234 123.476 0.115234C124.4 0.115234 125.267 0.249363 126.078 0.517621C126.895 0.785879 127.631 1.17932 128.287 1.69795C128.943 2.21062 129.491 2.83656 129.932 3.57576C130.373 4.30899 130.677 5.14655 130.844 6.08844Z"
      fill="#FCD34D"
    />
    <path
      d="M102.971 24.1179C101.952 24.1179 101.075 23.9867 100.342 23.7244C99.6088 23.4681 98.9978 23.1283 98.509 22.7051C98.0261 22.2878 97.6416 21.8407 97.3555 21.3638L99.0366 20.1834C99.2273 20.4338 99.4688 20.72 99.7609 21.0419C100.053 21.3697 100.452 21.6529 100.959 21.8914C101.472 22.1358 102.142 22.258 102.971 22.258C104.08 22.258 104.995 21.9897 105.716 21.4532C106.437 20.9167 106.798 20.0761 106.798 18.9316V16.1417H106.619C106.464 16.3921 106.244 16.7021 105.958 17.0717C105.677 17.4353 105.272 17.7602 104.742 18.0463C104.217 18.3265 103.508 18.4666 102.613 18.4666C101.505 18.4666 100.509 18.2043 99.6267 17.6797C98.7504 17.1551 98.0559 16.3921 97.5433 15.3906C97.0366 14.3891 96.7832 13.173 96.7832 11.7423C96.7832 10.3354 97.0306 9.11037 97.5254 8.06715C98.0202 7.01796 98.7087 6.20723 99.591 5.63494C100.473 5.0567 101.493 4.76758 102.649 4.76758C103.543 4.76758 104.253 4.91661 104.777 5.21467C105.308 5.50678 105.713 5.84061 105.993 6.21617C106.28 6.58577 106.5 6.88979 106.655 7.12824H106.87V4.94642H108.908V19.0746C108.908 20.255 108.64 21.2147 108.104 21.9539C107.573 22.6991 106.858 23.2446 105.958 23.5903C105.063 23.942 104.068 24.1179 102.971 24.1179ZM102.899 16.5709C103.746 16.5709 104.461 16.3772 105.046 15.9897C105.63 15.6022 106.074 15.0448 106.378 14.3175C106.682 13.5903 106.834 12.7199 106.834 11.7065C106.834 10.7169 106.685 9.84361 106.387 9.08652C106.089 8.32944 105.648 7.73629 105.063 7.30708C104.479 6.87787 103.758 6.66326 102.899 6.66326C102.005 6.66326 101.26 6.88979 100.664 7.34285C100.074 7.79591 99.6297 8.40396 99.3317 9.167C99.0395 9.93004 98.8935 10.7765 98.8935 11.7065C98.8935 12.6603 99.0425 13.5038 99.3406 14.2371C99.6446 14.9643 100.092 15.5366 100.682 15.9539C101.278 16.3652 102.017 16.5709 102.899 16.5709Z"
      fill="#FCD34D"
    />
    <path
      d="M86.8232 10.4189V18.6812H84.7129V4.94642H86.7516V7.09248H86.9305C87.2524 6.39501 87.7412 5.83465 88.397 5.4114C89.0527 4.98218 89.8992 4.76758 90.9365 4.76758C91.8664 4.76758 92.6801 4.95834 93.3776 5.33986C94.0751 5.71542 94.6175 6.2877 95.005 7.05671C95.3925 7.81975 95.5863 8.78548 95.5863 9.95389V18.6812H93.476V10.097C93.476 9.01797 93.1958 8.17743 92.6354 7.57534C92.0751 6.96729 91.3061 6.66326 90.3284 6.66326C89.6548 6.66326 89.0527 6.80932 88.5221 7.10142C87.9975 7.39352 87.5832 7.81975 87.2792 8.38011C86.9752 8.94047 86.8232 9.62006 86.8232 10.4189Z"
      fill="#FCD34D"
    />
    <path
      d="M77.2802 18.9673C76.0403 18.9673 74.9523 18.6723 74.0164 18.0821C73.0865 17.4919 72.3592 16.6663 71.8346 15.6052C71.316 14.5441 71.0566 13.3041 71.0566 11.8853C71.0566 10.4546 71.316 9.20575 71.8346 8.13868C72.3592 7.07161 73.0865 6.24299 74.0164 5.65283C74.9523 5.06266 76.0403 4.76758 77.2802 4.76758C78.5202 4.76758 79.6051 5.06266 80.5351 5.65283C81.471 6.24299 82.1983 7.07161 82.7169 8.13868C83.2415 9.20575 83.5038 10.4546 83.5038 11.8853C83.5038 13.3041 83.2415 14.5441 82.7169 15.6052C82.1983 16.6663 81.471 17.4919 80.5351 18.0821C79.6051 18.6723 78.5202 18.9673 77.2802 18.9673ZM77.2802 17.0717C78.2221 17.0717 78.9971 16.8302 79.6051 16.3474C80.2132 15.8645 80.6632 15.2296 80.9553 14.4427C81.2474 13.6558 81.3935 12.8034 81.3935 11.8853C81.3935 10.9673 81.2474 10.1119 80.9553 9.31901C80.6632 8.52616 80.2132 7.88533 79.6051 7.3965C78.9971 6.90768 78.2221 6.66326 77.2802 6.66326C76.3383 6.66326 75.5634 6.90768 74.9553 7.3965C74.3473 7.88533 73.8972 8.52616 73.6051 9.31901C73.313 10.1119 73.1669 10.9673 73.1669 11.8853C73.1669 12.8034 73.313 13.6558 73.6051 14.4427C73.8972 15.2296 74.3473 15.8645 74.9553 16.3474C75.5634 16.8302 76.3383 17.0717 77.2802 17.0717Z"
      fill="#FCD34D"
    />
    <path
      d="M67.9561 4.94387C67.8488 4.03776 67.4137 3.33433 66.6506 2.83358C65.8876 2.33283 64.9516 2.08246 63.8429 2.08246C63.0321 2.08246 62.3227 2.2136 61.7147 2.4759C61.1126 2.7382 60.6416 3.09885 60.3019 3.55787C59.968 4.01689 59.8011 4.5385 59.8011 5.12271C59.8011 5.61153 59.9173 6.0318 60.1498 6.38352C60.3883 6.72927 60.6923 7.01839 61.0619 7.25088C61.4315 7.47741 61.819 7.66519 62.2244 7.81423C62.6297 7.9573 63.0023 8.07354 63.3421 8.16296L65.202 8.66371C65.6789 8.78889 66.2095 8.96177 66.7937 9.18234C67.3839 9.40291 67.9472 9.70395 68.4837 10.0855C69.0262 10.461 69.4733 10.9439 69.825 11.5341C70.1767 12.1242 70.3526 12.8485 70.3526 13.7069C70.3526 14.6965 70.0933 15.5907 69.5746 16.3895C69.062 17.1883 68.3108 17.8232 67.3213 18.2942C66.3376 18.7651 65.1424 19.0006 63.7355 19.0006C62.4241 19.0006 61.2884 18.7889 60.3287 18.3657C59.3749 17.9424 58.6238 17.3523 58.0753 16.5952C57.5328 15.8381 57.2258 14.9588 57.1543 13.9573H59.4434C59.503 14.6488 59.7355 15.2211 60.1409 15.6742C60.5522 16.1213 61.0709 16.4551 61.6968 16.6757C62.3287 16.8903 63.0083 16.9976 63.7355 16.9976C64.5821 16.9976 65.3421 16.8605 66.0157 16.5862C66.6894 16.3061 67.2229 15.9186 67.6163 15.4238C68.0098 14.923 68.2065 14.3388 68.2065 13.6712C68.2065 13.0631 68.0366 12.5683 67.6968 12.1868C67.357 11.8053 66.9099 11.4953 66.3555 11.2569C65.8011 11.0184 65.202 10.8098 64.5582 10.6309L62.3048 9.98711C60.8741 9.57578 59.7415 8.9886 58.9069 8.22555C58.0723 7.46251 57.655 6.464 57.655 5.23001C57.655 4.20467 57.9322 3.31048 58.4866 2.54744C59.047 1.77843 59.7981 1.1823 60.74 0.759053C61.6878 0.32984 62.746 0.115234 63.9144 0.115234C65.0947 0.115234 66.1439 0.32686 67.0619 0.75011C67.98 1.1674 68.7073 1.73968 69.2438 2.46696C69.7862 3.19423 70.0724 4.01987 70.1022 4.94387H67.9561Z"
      fill="#FCD34D"
    />
    <path
      d="M49.2188 18.9644C48.0742 18.9644 47.0638 18.6753 46.1875 18.0971C45.3112 17.5129 44.6256 16.6902 44.1309 15.6291C43.6361 14.562 43.3887 13.3012 43.3887 11.8467C43.3887 10.404 43.6361 9.15216 44.1309 8.09105C44.6256 7.02994 45.3142 6.21027 46.1964 5.63202C47.0787 5.05378 48.0981 4.76466 49.2546 4.76466C50.1488 4.76466 50.8552 4.91369 51.3738 5.21175C51.8984 5.50386 52.2978 5.83769 52.572 6.21325C52.8522 6.58285 53.0698 6.88687 53.2248 7.12532H53.4036V0.365234H55.5139V18.6783H53.4752V16.568H53.2248C53.0698 16.8184 52.8492 17.1343 52.5631 17.5158C52.2769 17.8914 51.8686 18.2282 51.338 18.5263C50.8075 18.8184 50.1011 18.9644 49.2188 18.9644ZM49.5049 17.0687C50.3514 17.0687 51.0668 16.8482 51.651 16.407C52.2352 15.9599 52.6793 15.3429 52.9833 14.5561C53.2874 13.7632 53.4394 12.8482 53.4394 11.8109C53.4394 10.7855 53.2904 9.88838 52.9923 9.11937C52.6942 8.34441 52.2531 7.74232 51.6689 7.3131C51.0847 6.87793 50.3634 6.66034 49.5049 6.66034C48.6108 6.66034 47.8656 6.88985 47.2695 7.34887C46.6793 7.80193 46.2352 8.41892 45.9371 9.19985C45.645 9.97482 45.499 10.8452 45.499 11.8109C45.499 12.7885 45.648 13.6768 45.9461 14.4756C46.2501 15.2684 46.6972 15.9003 47.2874 16.3713C47.8835 16.8362 48.6227 17.0687 49.5049 17.0687Z"
      fill="#FCD34D"
    />
    <path
      d="M36.6044 18.9673C35.3645 18.9673 34.2766 18.6723 33.3406 18.0821C32.4107 17.4919 31.6834 16.6663 31.1588 15.6052C30.6402 14.5441 30.3809 13.3041 30.3809 11.8853C30.3809 10.4546 30.6402 9.20575 31.1588 8.13868C31.6834 7.07161 32.4107 6.24299 33.3406 5.65283C34.2766 5.06266 35.3645 4.76758 36.6044 4.76758C37.8444 4.76758 38.9293 5.06266 39.8593 5.65283C40.7952 6.24299 41.5225 7.07161 42.0411 8.13868C42.5657 9.20575 42.828 10.4546 42.828 11.8853C42.828 13.3041 42.5657 14.5441 42.0411 15.6052C41.5225 16.6663 40.7952 17.4919 39.8593 18.0821C38.9293 18.6723 37.8444 18.9673 36.6044 18.9673ZM36.6044 17.0717C37.5463 17.0717 38.3213 16.8302 38.9293 16.3474C39.5374 15.8645 39.9875 15.2296 40.2796 14.4427C40.5717 13.6558 40.7177 12.8034 40.7177 11.8853C40.7177 10.9673 40.5717 10.1119 40.2796 9.31901C39.9875 8.52616 39.5374 7.88533 38.9293 7.3965C38.3213 6.90768 37.5463 6.66326 36.6044 6.66326C35.6626 6.66326 34.8876 6.90768 34.2795 7.3965C33.6715 7.88533 33.2214 8.52616 32.9293 9.31901C32.6372 10.1119 32.4912 10.9673 32.4912 11.8853C32.4912 12.8034 32.6372 13.6558 32.9293 14.4427C33.2214 15.2296 33.6715 15.8645 34.2795 16.3474C34.8876 16.8302 35.6626 17.0717 36.6044 17.0717Z"
      fill="#FCD34D"
    />
    <path
      d="M23.5908 18.9673C22.3508 18.9673 21.2629 18.6723 20.327 18.0821C19.397 17.4919 18.6697 16.6663 18.1451 15.6052C17.6265 14.5441 17.3672 13.3041 17.3672 11.8853C17.3672 10.4546 17.6265 9.20575 18.1451 8.13868C18.6697 7.07161 19.397 6.24299 20.327 5.65283C21.2629 5.06266 22.3508 4.76758 23.5908 4.76758C24.8307 4.76758 25.9157 5.06266 26.8456 5.65283C27.7815 6.24299 28.5088 7.07161 29.0274 8.13868C29.552 9.20575 29.8143 10.4546 29.8143 11.8853C29.8143 13.3041 29.552 14.5441 29.0274 15.6052C28.5088 16.6663 27.7815 17.4919 26.8456 18.0821C25.9157 18.6723 24.8307 18.9673 23.5908 18.9673ZM23.5908 17.0717C24.5326 17.0717 25.3076 16.8302 25.9157 16.3474C26.5237 15.8645 26.9738 15.2296 27.2659 14.4427C27.558 13.6558 27.704 12.8034 27.704 11.8853C27.704 10.9673 27.558 10.1119 27.2659 9.31901C26.9738 8.52616 26.5237 7.88533 25.9157 7.3965C25.3076 6.90768 24.5326 6.66326 23.5908 6.66326C22.6489 6.66326 21.8739 6.90768 21.2659 7.3965C20.6578 7.88533 20.2077 8.52616 19.9156 9.31901C19.6235 10.1119 19.4775 10.9673 19.4775 11.8853C19.4775 12.8034 19.6235 13.6558 19.9156 14.4427C20.2077 15.2296 20.6578 15.8645 21.2659 16.3474C21.8739 16.8302 22.6489 17.0717 23.5908 17.0717Z"
      fill="#FCD34D"
    />
    <path
      d="M14.1188 6.08844C13.9221 5.48635 13.6628 4.94685 13.3409 4.46995C13.0249 3.98708 12.6464 3.57576 12.2052 3.23596C11.7701 2.89617 11.2753 2.63686 10.7209 2.45802C10.1665 2.27918 9.55843 2.18976 8.89672 2.18976C7.81177 2.18976 6.82518 2.46994 5.93695 3.0303C5.04872 3.59066 4.34231 4.4163 3.81771 5.50721C3.29312 6.59812 3.03083 7.93643 3.03083 9.52213C3.03083 11.1078 3.2961 12.4461 3.82666 13.5371C4.35721 14.628 5.07554 15.4536 5.98166 16.014C6.88777 16.5743 7.90715 16.8545 9.03979 16.8545C10.089 16.8545 11.013 16.631 11.8118 16.1839C12.6166 15.7308 13.2425 15.0929 13.6896 14.2703C14.1426 13.4417 14.3692 12.467 14.3692 11.3463L15.0488 11.4894H9.54054V9.52213H16.5152V11.4894C16.5152 12.9976 16.1933 14.309 15.5495 15.4238C14.9117 16.5386 14.0294 17.4029 12.9027 18.017C11.782 18.625 10.4943 18.929 9.03979 18.929C7.41833 18.929 5.99358 18.5475 4.76556 17.7845C3.5435 17.0214 2.58969 15.9365 1.90414 14.5296C1.22456 13.1227 0.884766 11.4536 0.884766 9.52213C0.884766 8.07354 1.07851 6.771 1.46599 5.61451C1.85943 4.45206 2.41383 3.46249 3.12919 2.6458C3.84454 1.8291 4.69104 1.20317 5.66869 0.767995C6.64634 0.332821 7.72235 0.115234 8.89672 0.115234C9.86245 0.115234 10.7626 0.261286 11.5972 0.553388C12.4377 0.839529 13.1859 1.24788 13.8416 1.77843C14.5033 2.30302 15.0547 2.93194 15.4959 3.66518C15.937 4.39245 16.241 5.20021 16.4079 6.08844H14.1188Z"
      fill="#FCD34D"
    />
    <path
      d="M112.449 22.8953L112.448 22.8948L112.44 22.8886L112.44 22.8885C110.887 21.6089 109.64 20.5805 108.776 19.6202C107.915 18.664 107.5 17.8459 107.5 16.9973C107.5 15.6391 108.682 14.5 110.3 14.5C111.21 14.5 112.078 14.887 112.635 15.481L113 15.87L113.365 15.481C113.922 14.887 114.79 14.5 115.7 14.5C117.318 14.5 118.5 15.6391 118.5 16.9973C118.5 17.8445 118.086 18.6612 117.229 19.616C116.367 20.5751 115.124 21.6024 113.575 22.881C113.575 22.881 113.575 22.881 113.575 22.881L113.553 22.8996L113.552 22.9005L113.001 23.3522L112.449 22.8953Z"
      fill="#FCD34D"
      stroke="#404040"
    />
  </svg>
);

export default LogoYellow;
