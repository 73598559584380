import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import styles from './SongListHome.module.scss';
import { useUser } from '../../config/UserContext';
import { useSongs } from '../../config/SongContext';
import SongListDay from '../SongListDay';
import Spinner from '../Spinner/Spinner';
import { useNavigation } from '../../config/NavigationContext';
import BrandHead from '../BrandHead/BrandHead';
import { debounce } from 'lodash';
import CookieNotification from '../CookieNotification/CookieNotification';
import UpArrow from '../Icons/UpArrow';

const SongListHome = ({ setNavContent, openSongPage }) => {
  const [loading, setLoading] = useState(true);

  const { handleUserPageNav } = useNavigation();
  const { currentUser } = useUser();
  const { songs, setSongs, loadMoreSongs } = useSongs();
  const containerRef = useRef(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [isAtBottom, setIsAtBottom] = useState(false);

  const saveScrollPosition = useCallback(
    debounce((scrollTop) => {
      sessionStorage.setItem('songListHomeScrollPosition', scrollTop);
    }, 100),
    []
  );

  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      const scrollHeight = containerRef.current.scrollHeight;
      const clientHeight = containerRef.current.clientHeight;
      setShowBackToTop(scrollTop > 600);
      setIsAtBottom(scrollTop + clientHeight >= scrollHeight - 240);
      saveScrollPosition(scrollTop);
    }
  }, [saveScrollPosition]);

  useEffect(() => {
    const storedScrollPosition = sessionStorage.getItem(
      'songListHomeScrollPosition'
    );
    if (storedScrollPosition && containerRef.current) {
      containerRef.current.scrollTop = parseInt(storedScrollPosition, 10);
      setShowBackToTop(containerRef.current.scrollTop > 600);
    }

    const currentContainer = containerRef.current;
    if (currentContainer) {
      currentContainer.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentContainer) {
        currentContainer.removeEventListener('scroll', handleScroll);
      }
      saveScrollPosition.cancel();
    };
  }, [handleScroll, saveScrollPosition]);

  useEffect(() => {
    const loadData = async () => {
      console.log('Loading data...');
      await new Promise((resolve) => setTimeout(resolve, 2000));
      console.log('Data loaded');
      setLoading(false);
    };

    loadData();
  }, []);

  const onDelete = (songId) => {
    setSongs((prevSongs) => prevSongs.filter((song) => song.id !== songId));
  };

  const groupedByDay = songs.reduce((acc, song) => {
    const timestamp = song.timestamp?.toDate?.() || new Date();
    const date = moment(timestamp).format('dddd, MMMM Do YYYY');
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(song);
    return acc;
  }, {});

  const lastDaysWithSongs = Object.keys(groupedByDay);

  const handleLoadMore = () => {
    loadMoreSongs();
  };

  const handleBackToTop = () => {
    containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <div ref={containerRef} className={styles.container}>
        <BrandHead />
        <CookieNotification />
        {loading ? (
          <div className={styles['loading-container']}>
            <Spinner />
          </div>
        ) : (
          <>
            <ul className={styles.songList}>
              {lastDaysWithSongs.map((day) => (
                <SongListDay
                  key={day}
                  day={day}
                  songs={groupedByDay[day]}
                  onDelete={onDelete}
                  currentUser={currentUser}
                  setNavContent={setNavContent}
                  openSongPage={openSongPage}
                  handleUserPageNav={handleUserPageNav}
                />
              ))}
            </ul>

            <div className={styles.loadMoreContainer}>
              <button
                onClick={handleLoadMore}
                className={styles.loadMoreButton}
              >
                Load more...
              </button>
            </div>
            <div
              className={`${styles.backToTopWrapper} ${
                isAtBottom ? styles.hideBackToTop : ''
              }`}
            >
              <button
                onClick={handleBackToTop}
                className={`${styles.backToTopButton} ${
                  showBackToTop ? styles.showBackToTop : ''
                }`}
              >
                <UpArrow fill="#f5f5f5" />
                Back to Top
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(SongListHome);
