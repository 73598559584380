import React, { useState, useEffect } from 'react';
import styles from './BrandHead.module.scss';
import BrandHeadLogo from '../Icons/BrandHeadLogo';
import { useSongs } from '../../config/SongContext';
import External from '../Icons/External';
import Record from '../Icons/Record';
import DefaultPage from '../DefaultPage/DefaultPage';
import RecordIcon from '../Icons/Record';
import Close from '../Icons/Close';

function BrandHead() {
  const { totalSongCount } = useSongs();
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false);
  const [isDefaultPageModalOpen, setIsDefaultPageModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const toggleAboutModal = () => {
    setIsAboutModalOpen((prev) => !prev);
  };

  const toggleDefaultPageModal = () => {
    setIsDefaultPageModalOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClick = () => {
    if (isMobile) {
      toggleDefaultPageModal();
    } else {
      toggleAboutModal();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.recordIcon} onClick={handleClick}>
            <RecordIcon />
          </div>
          <div className={styles.stacked}>
            <p className={styles.paragraph}>Songs shared</p>
            <span className={styles.strong}>{totalSongCount}</span>
          </div>
        </div>
        <a
          href="https://digthis.club"
          target="_blank"
          className={styles.stackedRight}
          rel="noreferrer"
          id="ABOUT"
        >
          <p className={styles.paragraph}>Looking for inspiration?</p>

          <span className={styles.strong}>
            Dig This
            <External id="external-link" />
          </span>
        </a>
      </div>
      <div className={styles.body}>
        <div className={styles.logo}>
          Hello, this is
          <h1 className={styles.title}>
            Good Song <br />
            Club
          </h1>
        </div>
        <div>
          <span className={styles.recentPosts}>
            Recent posts <span className={styles.bob}>↓</span>
          </span>
        </div>
      </div>

      {/* Ad-Hoc Modal for DefaultPage */}
      {isDefaultPageModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <button
              className={styles.closeButton}
              onClick={toggleDefaultPageModal}
            >
              <Close />
            </button>
            <DefaultPage />
          </div>
        </div>
      )}
    </div>
  );
}

export default BrandHead;
