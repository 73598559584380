import React from 'react';

const Check = ({ fill = '#404040' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1164_1654)">
      <path
        d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1164_1654">
        <rect width="24" height="24" fill="none" />
      </clipPath>
    </defs>
  </svg>
);

export default Check;
