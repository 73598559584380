import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy = () => {
  return (
    <div className={styles.privacyPolicyContainer}>
      <h1 className={styles.title}>Privacy Policy for Good Song Club</h1>
      <p className={styles.effectiveDate}>
        <strong>Effective Date:</strong> 10th February 2025
      </p>
      <p className={styles.intro}>
        Good Song Club ("we," "our," or "us") respects your privacy and is
        committed to protecting your personal data. This Privacy Policy explains
        how we collect, use, share, and protect your information when you use
        https://goodsong.club (the "Service").
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>1. Information We Collect</h2>
      <p className={styles.sectionContent}>
        We collect the following types of data:
      </p>
      <ul className={styles.policyList}>
        <li className={styles.listItem}>
          <strong>Personal Data:</strong>
          <ul className={styles.subList}>
            <li className={styles.subListItem}>
              When you sign up or log in via Google, we collect your email
              address, display name, and profile information through OAuth.
            </li>
            <li className={styles.subListItem}>
              Users may provide additional information such as bios, avatar
              images, and links to external social profiles (Instagram, Twitter,
              etc.).
            </li>
          </ul>
        </li>
        <li className={styles.listItem}>
          <strong>Activity Data:</strong>
          <ul className={styles.subList}>
            <li className={styles.subListItem}>
              Posts, comments, likes, and other user-generated content within
              the app.
            </li>
          </ul>
        </li>
        <li className={styles.listItem}>
          <strong>Automatically Collected Data:</strong>
          <ul className={styles.subList}>
            <li className={styles.subListItem}>
              We collect information such as IP addresses, browser type, and
              usage data through cookies and Google Analytics.
            </li>
          </ul>
        </li>
      </ul>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>2. How We Use Your Information</h2>
      <p className={styles.sectionContent}>We use your data to:</p>
      <ul className={styles.policyList}>
        <li className={styles.listItem}>
          Enable core app functions, such as posting songs, commenting, and
          editing user profiles.
        </li>
        <li className={styles.listItem}>
          Maintain and secure user accounts through Firebase authentication.
        </li>
        <li className={styles.listItem}>
          Track and analyze app performance with Google Analytics.
        </li>
        <li className={styles.listItem}>
          Provide a personalized user experience.
        </li>
      </ul>
      <p className={styles.sectionContent}>
        We do not sell or rent your personal data to third parties.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>3. Sharing of Data</h2>
      <p className={styles.sectionContent}>We share your data only with:</p>
      <ul className={styles.policyList}>
        <li className={styles.listItem}>
          <strong>Firebase:</strong> For authentication, data storage, and
          backend services.
        </li>
        <li className={styles.listItem}>
          <strong>Google Analytics:</strong> For analyzing traffic and app
          performance.
        </li>
      </ul>
      <p className={styles.sectionContent}>
        These third parties are bound by their own privacy policies and comply
        with data protection laws.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>4. Cookies and Tracking</h2>
      <p className={styles.sectionContent}>
        We use cookies to enhance your experience. These cookies help with login
        sessions, user preferences, and analytics.
      </p>
      <p className={styles.sectionContent}>
        First-time visitors are presented with a cookie disclaimer and
        acknowledgment mechanism. You may disable cookies through your browser
        settings, although this may affect your use of the Service.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>5. User Rights</h2>
      <p className={styles.sectionContent}>You have the right to:</p>
      <ul className={styles.policyList}>
        <li className={styles.listItem}>
          <strong>Access Your Data:</strong> Request details of your personal
          data stored by us.
        </li>
        <li className={styles.listItem}>
          <strong>Delete Your Data:</strong> Request account deletion and
          removal of personal data by contacting us at{' '}
          <strong>hello@goodsong.club</strong>.
        </li>
        <li className={styles.listItem}>
          <strong>Correct Your Data:</strong> Update or modify your account
          details through the app.
        </li>
      </ul>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>6. Data Retention</h2>
      <p className={styles.sectionContent}>
        We retain your data indefinitely unless a formal deletion request is
        submitted. Deleted data may remain in backups for a limited time but
        will be permanently removed in due course.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>7. Security</h2>
      <p className={styles.sectionContent}>
        We rely on Firebase's built-in security measures to protect user data,
        including encrypted connections and secure storage. However, no system
        can guarantee complete security, and users are responsible for
        safeguarding their login credentials.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>8. Children's Privacy</h2>
      <p className={styles.sectionContent}>
        Good Song Club is not intended for children under the age of{' '}
        <strong>13</strong>. We do not knowingly collect personal data from
        users under this age. If we discover that data has been collected from a
        child, we will take steps to delete it.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>9. Changes to This Policy</h2>
      <p className={styles.sectionContent}>
        We may update this Privacy Policy to reflect changes in our practices or
        services. Users will be notified of significant changes through the app
        or via email. Continued use of the Service constitutes acceptance of the
        updated policy.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>10. Contact Us</h2>
      <p className={styles.sectionContent}>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at <strong>hello@goodsong.club</strong>.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
