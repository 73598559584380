import React from 'react';

const Instagram = ({ fill = '#fcd34d' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <rect x="2.5" y="2.5" width="15" height="15" rx="4.5" stroke={fill} />
      <circle cx="10" cy="10" r="3.5" stroke={fill} />
      <circle cx="14" cy="6" r="1" fill={fill} />
    </svg>
  );
};

export default Instagram;
