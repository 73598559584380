import React, { useState } from 'react';
import AuthContainer from '../auth/AuthContainer';
import styles from './Toolbar.module.scss';
import { useUser } from '../../config/UserContext';
import { useNotifications } from '../../config/NotificationContext';
import { Link } from 'react-router-dom';
import { auth } from '../../config/firebase';
import Check from '../Icons/Check';
import Logo from '../Icons/logo';
import Plus from '../Icons/Plus';
import Notification from '../Icons/Notification';
import NotificationActive from '../Icons/NotificationActive';
import PostFormModal from '../PostFormModal';
import useHasPostedToday from '../../hooks/useHasPostedToday';
import { useNavigation } from '../../config/NavigationContext';
import Record from '../Icons/Record';
import Modal from '../utils/Modal';
import AboutPage from '../AboutPage/AboutPage';

const Toolbar = () => {
  const { currentUser } = useUser();
  const { hasUnreadNotifications } = useNotifications();

  const userId = auth.currentUser ? auth.currentUser.uid : null;

  const [isPostFormModalOpen, setIsPostFormModalOpen] = useState(false);
  const [isAboutModalOpen, setIsAboutModalOpen] = useState(false); // State for About modal
  const { handleUserPageNav, handleHomeNav, handleNotificationsNav } =
    useNavigation();

  const togglePostFormModal = () => {
    setIsPostFormModalOpen((prev) => !prev);
  };

  const toggleAboutModal = () => {
    setIsAboutModalOpen((prev) => !prev);
  };

  const alreadyPosted = useHasPostedToday(userId);

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.top}>
          <Link
            className={styles.toolbarLinkTop}
            onClick={(e) => {
              e.preventDefault();
              toggleAboutModal(); // Toggle About modal on Record click
            }}
            to="#"
          >
            <Record />
          </Link>
          <Link
            className={styles.navLink}
            style={{
              marginLeft: '-2px',
            }}
            onClick={(e) => {
              e.preventDefault();
              handleHomeNav();
            }}
            to="/"
          >
            <Logo />
          </Link>
        </div>

        <div className={styles.bottom}>
          {!currentUser ? (
            <AuthContainer />
          ) : (
            <>
              <div className={styles.toolbarLinks}>
                <Link
                  className={!alreadyPosted ? styles.postCTA : styles.postedCTA}
                  onClick={(e) => {
                    e.preventDefault();
                    togglePostFormModal();
                  }}
                >
                  {alreadyPosted ? (
                    <Check fill="#0a0a0a" />
                  ) : (
                    <span className={styles.ninetyDegrees}>
                      <Plus fill="#0a0a0a" />
                      {alreadyPosted ? '' : 'Post a song'}
                    </span>
                  )}
                </Link>
                <Link
                  className={styles.toolbarLink}
                  onClick={(e) => {
                    e.preventDefault();
                    handleNotificationsNav(userId);
                  }}
                >
                  {hasUnreadNotifications ? (
                    <NotificationActive fill="#0a0a0a" />
                  ) : (
                    <Notification fill="#0a0a0a" />
                  )}
                </Link>
                {currentUser ? (
                  <Link
                    className={styles.toolbarLink}
                    onClick={(e) => {
                      e.preventDefault();
                      handleUserPageNav(userId);
                    }}
                  >
                    <img
                      className={styles.toolbarAvatar}
                      src={currentUser.avatar}
                      alt={currentUser.displayName}
                    />
                  </Link>
                ) : (
                  <>Loading... </>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <PostFormModal show={isPostFormModalOpen} onClose={togglePostFormModal} />

      <Modal
        size="small"
        show={isAboutModalOpen}
        onClose={toggleAboutModal}
        title="Good Song Club"
      >
        <AboutPage />
      </Modal>
    </>
  );
};

export default Toolbar;
