import React from 'react';

const DownIcon = ({ width = 24, height = 24, fill = '#404040', ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1914_2426)">
      <path
        d="M20 12L18.59 10.59L13 16.17V4H11V16.17L5.42 10.58L4 12L12 20L20 12Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1914_2426">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default DownIcon;
