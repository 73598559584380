import React from 'react';

const Home = ({ fill = '#404040' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1098_5521)">
      <path
        d="M10 20V14H14V20H19V12H22L14.0069 4.80621C12.866 3.77938 11.134 3.77938 9.99311 4.8062L2 12H5V20H10Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1098_5521">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Home;
