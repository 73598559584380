import React, { useState } from 'react';
import styles from './Footer.module.scss';
import LogoYellow from '../Icons/LogoYellow';
import Modal from '../utils/Modal';
import Tos from '../Tos/Tos';
import PrivacyPolicy from '../PrivacyPolicy/PrivacyPolicy';
import Instagram from '../Icons/Instagram';

const Footer = () => {
  const [showTosModal, setShowTosModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  const handleTosClick = () => setShowTosModal(true);
  const handlePrivacyClick = () => setShowPrivacyModal(true);
  const handleCloseModal = () => {
    setShowTosModal(false);
    setShowPrivacyModal(false);
  };

  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <div className={styles.footer}>
      <div className={styles.footerHead}>
        <LogoYellow />
      </div>
      <div className={styles.footerChunk}>
        <div className={styles.footerBody}>
          <div className={styles.footerColumn}>
            <p className={styles.footerText}>
              Good Song Club was conceived, designed, and built by{' '}
              <a
                href="https://robysaavedra.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Roby Saavedra
              </a>{' '}
              in beautiful Philadelphia, PA.
            </p>
          </div>
          <div className={styles.footerColumn}></div>
          <div className={styles.footerColumn}>
            <p className={styles.footerText}>
              <a href="#" onClick={handleTosClick}>
                Terms of Service
              </a>
            </p>
            <p className={styles.footerText}>
              <a href="#" onClick={handlePrivacyClick}>
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
        <div className={styles.footerBottom}>
          <span className={styles.footerBottomLeft}>
            <a href="mailto:hello@goodsong.club">hello@goodsong.club</a>
            <span className={styles.separator}>|</span>
            <a
              href="https://www.instagram.com/goodsong.club"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.socialLink}
            >
              <Instagram />
            </a>
          </span>
          <span className={styles.footerBottomRight}>
            Good Song Club &copy; {currentYear}
          </span>
        </div>
      </div>

      {/* Terms of Service Modal */}
      <Modal
        show={showTosModal}
        onClose={handleCloseModal}
        title="Terms of Service"
      >
        <Tos />
      </Modal>

      {/* Privacy Policy Modal */}
      <Modal
        show={showPrivacyModal}
        onClose={handleCloseModal}
        title="Privacy Policy"
      >
        <PrivacyPolicy />
      </Modal>
    </div>
  );
};

export default Footer;
