import React, { useEffect, useState, useCallback, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slider.css';
import External from '../Icons/External';
import styles from './DefaultPage.module.scss';
import {
  getFirestore,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  where,
  doc,
  getDoc,
} from 'firebase/firestore';
import Asterisk from '../Icons/Asterisk';
import Footer from '../Footer/Footer';

import LogoYellow from '../Icons/LogoYellow';
const DefaultPage = ({ openSongPage }) => {
  const [carouselSongs, setCarouselSongs] = useState([]); // Songs for the carousel
  const [chipSongs, setChipSongs] = useState([]); // Songs for the chips
  const [error, setError] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(() => {
    const savedState = localStorage.getItem('defaultPageIsOpen');
    return savedState === 'true';
  });
  const [isFading, setIsFading] = useState(false);
  const [isComponentLoading, setIsComponentLoading] = useState(true); // Track loading state for the entire component
  const [sliderKey, setSliderKey] = useState(0); // Key to reset slider
  const db = getFirestore();

  const fetchRandomSongs = useCallback(
    async (setSongs, forceFetch = false) => {
      console.log('Fetching random songs...'); // Debug log
      setIsRefreshing(true);
      setIsLoading(true);
      try {
        const randomValue = Math.random();
        const songsRef = collection(db, 'songs');

        let songQuery = query(
          songsRef,
          where('random', '>=', randomValue),
          orderBy('random'),
          limit(10)
        );

        let snapshot = await getDocs(songQuery);

        if (snapshot.size < 10) {
          const fallbackQuery = query(
            songsRef,
            where('random', '<', randomValue),
            orderBy('random'),
            limit(10 - snapshot.size)
          );
          const fallbackSnapshot = await getDocs(fallbackQuery);
          snapshot = {
            docs: [...snapshot.docs, ...fallbackSnapshot.docs],
          };
        }

        const songList = await Promise.all(
          snapshot.docs.map(async (docSnap) => {
            const songData = { id: docSnap.id, ...docSnap.data() };
            try {
              const userRef = doc(db, 'users', songData.userId);
              const userSnap = await getDoc(userRef);
              if (userSnap.exists()) {
                songData.user = userSnap.data();
              }
            } catch (userError) {
              console.error(
                `Error fetching user data for ID: ${songData.userId}`,
                userError
              );
            }
            return songData;
          })
        );

        console.log('Fetched songs:', songList); // Debug log
        setSongs(songList);
      } catch (err) {
        console.error('Error fetching random songs:', err);
        setError('Failed to load random songs. Please try again later.');
      } finally {
        setIsRefreshing(false);
        setIsLoading(false);
        setIsComponentLoading(false); // Set loading to false once data is fetched
      }
    },
    [db]
  );

  useEffect(() => {
    if (!carouselSongs.length) {
      console.log('Component mounted: Fetching carousel songs');
      fetchRandomSongs(setCarouselSongs, true);
    }
  }, [fetchRandomSongs, carouselSongs.length]);

  useEffect(() => {
    if (isOpen) {
      fetchRandomSongs(setChipSongs, true);
    }
  }, [isOpen, fetchRandomSongs]);

  useEffect(() => {
    localStorage.setItem('defaultPageIsOpen', isOpen);
  }, [isOpen]);

  // Reset slider key when component mounts
  useEffect(() => {
    setSliderKey((prevKey) => prevKey + 1);
  }, []);

  const toggleOpen = () => {
    if (isOpen) {
      setIsFading(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsFading(false);
      }, 300);
    } else {
      setChipSongs([]);
      setIsOpen(true);
    }
  };

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
    arrows: false,
    adaptiveHeight: false,
    cssEase: 'ease-in-out',
  };

  if (error) {
    return <div className={styles.error}>{error}</div>;
  }

  return (
    <div
      className={`${styles.container} ${
        isComponentLoading ? styles.loading : styles.loaded
      }`}
    >
      <div className={styles.landingPage}>
        <div className={styles.landingPageContent}>
          <div className={styles.headerBar}>
            <div className={styles.headerBarContent}>
              <p className={styles.headerBarText}>About</p>
            </div>
          </div>
          <div className={styles.landingPageHeader}>
            <div className={styles.headerText}>
              <p>
                Welcome to Good Song Club - we're an inclusive community of
                music enthusiasts sharing good songs that we've found on
                YouTube.
              </p>
              <p>
                We believe that the best recommendations come not from cold
                algorithms, but warm hearts.
              </p>
            </div>
          </div>
          <div className={styles.landingPageBody}>
            <div className={styles.sliderContainer}>
              {isComponentLoading ? (
                <div className={styles.loadingBar}></div>
              ) : (
                <Slider key={sliderKey} {...sliderSettings}>
                  {carouselSongs.map((song) => (
                    <div key={song.id} onClick={() => openSongPage(song.id)}>
                      <div
                        className={styles.thumbnailImage}
                        style={{ backgroundImage: `url(${song.thumbnailURL})` }}
                      >
                        <div className={styles.avatarContainer}>
                          <img
                            src={song.user.avatar || './Avatar.png'}
                            alt="avi"
                            className={styles.userThumbnail}
                          />
                        </div>
                      </div>
                      <div className={styles.songInfo}>
                        <span className={styles.songName}>{song.songName}</span>
                        <span className={styles.artist}>{song.songArtist}</span>
                      </div>
                    </div>
                  ))}
                </Slider>
              )}
            </div>
            {/* How It Works Section */}
            <div className={styles.howItWorksContainer}>
              <div className={styles.howItWorks}>
                <p className={styles.howItWorksTitle}>How It Works</p>
                <div className={styles.steps}>
                  <div className={styles.step}>
                    <div className={styles.stepTitle}>
                      <Asterisk />
                      <h3>Step 1</h3>
                    </div>
                    <p>
                      Find a song you like on YouTube.
                      <br />
                      <br />
                      <i>
                        If you're looking for inspiration check out these
                        resources: <br />
                      </i>
                      <a
                        href="https://lazyrecords.app/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        lazyrecords.app
                      </a>
                      <br />
                      <a
                        href="https://digthis.club/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        digthis.club
                      </a>
                    </p>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.stepTitle}>
                      <Asterisk />
                      <h3>Step 2</h3>
                    </div>
                    <p>
                      Copy the url of the youtube video.
                      <br />
                      <br />
                      <i>
                        You can use full youtube urls, shortened urls, and even
                        urls from&nbsp;
                      </i>
                      <a
                        href="https://music.youtube.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Youtube Music
                      </a>
                      .
                    </p>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.stepTitle}>
                      <Asterisk />
                      <h3>Step 3</h3>
                    </div>
                    <p>
                      Paste the url here and share it with your buds.
                      <br />
                      <br />
                      <i>
                        Also, if you feel like it, you're welcome to wax poetic
                        about what makes the song special. In fact, it's
                        encouraged.
                      </i>
                    </p>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.stepTitle}>
                      <Asterisk />
                      <h3>Step 4</h3>
                    </div>
                    <p>
                      Listen to other songs your buds have shared.
                      <br />
                      <br />
                      <i>
                        Revel in the exchange! Find your new favorite song! Make
                        new friends! Embrace the dialectic!
                      </i>
                    </p>
                  </div>
                  <div className={styles.step}>
                    <div className={styles.stepTitle}>
                      <Asterisk />
                      <h3>Step 5</h3>
                    </div>
                    <p>
                      Repeat steps 1-4 every day <strong>forever</strong>.
                      <br />
                      <br />
                      <i>
                        Just kidding. Post whenever you feel like it, live your
                        life, go outside. You don't even have to post at all. If
                        you just want to lurk and listen that's beautiful too.
                      </i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultPage;
