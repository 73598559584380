import React from 'react';

const CommentLike = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <mask id="path-1-inside-1_2155_2566" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 2C21.1 2 21.99 2.9 21.99 4L22 22L18 19H4C2.9 19 2 18.1 2 17V4C2 2.9 2.9 2 4 2H20ZM11.275 14.3526L12 15L12.725 14.3575L12.7447 14.3399C15.3087 12.0544 17 10.5468 17 8.69755C17 7.18692 15.79 6 14.25 6C13.38 6 12.545 6.39728 12 7.02507C11.455 6.39728 10.62 6 9.75 6C8.21 6 7 7.18692 7 8.69755C7 10.55 8.69721 12.0596 11.2687 14.347L11.275 14.3526Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 2C21.1 2 21.99 2.9 21.99 4L22 22L18 19H4C2.9 19 2 18.1 2 17V4C2 2.9 2.9 2 4 2H20ZM11.275 14.3526L12 15L12.725 14.3575L12.7447 14.3399C15.3087 12.0544 17 10.5468 17 8.69755C17 7.18692 15.79 6 14.25 6C13.38 6 12.545 6.39728 12 7.02507C11.455 6.39728 10.62 6 9.75 6C8.21 6 7 7.18692 7 8.69755C7 10.55 8.69721 12.0596 11.2687 14.347L11.275 14.3526Z"
        fill="#FEC738"
      />
      <path
        d="M21.99 4L20.99 4L20.99 4.00056L21.99 4ZM22 22L21.4 22.8L23.0011 24.0008L23 21.9994L22 22ZM18 19L18.6 18.2L18.3333 18H18V19ZM12 15L11.3339 15.7459L11.9975 16.3384L12.6632 15.7484L12 15ZM11.275 14.3526L11.9411 13.6067L11.9396 13.6054L11.275 14.3526ZM12.725 14.3575L13.3882 15.1059L13.3904 15.104L12.725 14.3575ZM12.7447 14.3399L13.4101 15.0864L13.4102 15.0864L12.7447 14.3399ZM12 7.02507L11.2449 7.68063L12 8.55049L12.7551 7.68063L12 7.02507ZM11.2687 14.347L10.604 15.0941L10.6041 15.0942L11.2687 14.347ZM22.99 4C22.99 2.35425 21.6588 1 20 1V3C20.5412 3 20.99 3.44575 20.99 4H22.99ZM23 21.9994L22.99 3.99944L20.99 4.00056L21 22.0006L23 21.9994ZM17.4 19.8L21.4 22.8L22.6 21.2L18.6 18.2L17.4 19.8ZM4 20H18V18H4V20ZM1 17C1 18.6523 2.34772 20 4 20V18C3.45228 18 3 17.5477 3 17H1ZM1 4V17H3V4H1ZM4 1C2.34772 1 1 2.34772 1 4H3C3 3.45228 3.45228 3 4 3V1ZM20 1H4V3H20V1ZM12.6661 14.2541L11.9411 13.6067L10.6089 15.0985L11.3339 15.7459L12.6661 14.2541ZM12.0618 13.6091L11.3368 14.2516L12.6632 15.7484L13.3882 15.1059L12.0618 13.6091ZM12.0794 13.5934L12.0596 13.611L13.3904 15.104L13.4101 15.0864L12.0794 13.5934ZM16 8.69755C16 9.28819 15.739 9.90526 15.065 10.7157C14.3801 11.5392 13.3816 12.4326 12.0793 13.5934L13.4102 15.0864C14.6719 13.9617 15.801 12.9585 16.6027 11.9946C17.4153 11.0175 18 9.95611 18 8.69755H16ZM14.25 7C15.256 7 16 7.75727 16 8.69755H18C18 6.61657 16.324 5 14.25 5V7ZM12.7551 7.68063C13.1141 7.2671 13.6757 7 14.25 7V5C13.0843 5 11.9759 5.52745 11.2449 6.36951L12.7551 7.68063ZM9.75 7C10.3243 7 10.8859 7.2671 11.2449 7.68063L12.7551 6.36951C12.0241 5.52745 10.9157 5 9.75 5V7ZM8 8.69755C8 7.75727 8.74404 7 9.75 7V5C7.67596 5 6 6.61657 6 8.69755H8ZM11.9333 13.5998C10.6273 12.4381 9.62549 11.5437 8.93826 10.719C8.2619 9.90735 8 9.28921 8 8.69755H6C6 9.95832 6.58671 11.0212 7.40181 11.9994C8.20605 12.9644 9.33862 13.9685 10.604 15.0941L11.9333 13.5998ZM11.9396 13.6054L11.9332 13.5997L10.6041 15.0942L10.6104 15.0998L11.9396 13.6054Z"
        fill="#404040"
        mask="url(#path-1-inside-1_2155_2566)"
      />
    </svg>
  );
};

export default CommentLike;
