import React from 'react';

const HeartFilled = ({ fill = '#D4D4D4' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <g clipPath="url(#clip0_1000_624)">
      <path
        d="M10.8866 19.6603L10.8859 19.6596C8.30081 17.3155 6.19567 15.4057 4.73078 13.6147C3.27162 11.8307 2.5 10.2258 2.5 8.5C2.5 5.69614 4.69614 3.5 7.5 3.5C9.08861 3.5 10.6211 4.24197 11.6193 5.41417L12 5.8612L12.3807 5.41417C13.3789 4.24197 14.9114 3.5 16.5 3.5C19.3039 3.5 21.5 5.69614 21.5 8.5C21.5 10.2258 20.7284 11.8308 19.2691 13.6161C17.8065 15.4055 15.7058 17.3144 13.1265 19.6583L13.1148 19.669L13.1137 19.67L12.0013 20.675L10.8866 19.6603Z"
        fill={fill}
        stroke="#404040"
      />
    </g>
    <defs>
      <clipPath id="clip0_1000_624">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HeartFilled;
