import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import {
  doc,
  getDoc,
  collection,
  getDocs,
  query,
  where,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { db } from '../../config/firebase';
import { useSongs } from '../../config/SongContext';
import { useFollowUnfollow } from '../../hooks/FollowUnfollow';
import UserPageSong from '../UserPageSong/UserPageSong';
import EditProfile from '../EditProfile';
import Edit from '../Icons/Edit';
import OverflowMenu from '../utils/OverflowMenu';
import UserListItem from '../UserListItem/UserListItem';
import { Helmet } from 'react-helmet';
import styles from './UserPage.module.scss';
import PostFormModal from '../PostFormModal/PostFormModal';
import LikedSongsList from '../LikedSongsList/LikedSongsList';

const UserPage = ({ openSongPage }) => {
  const [likedSongs, setLikedSongs] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const { fetchSongsByUser, likeSong, unlikeSong } = useSongs();
  const [following, setFollowing] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [userData, setUserData] = useState(null);
  const [userSongs, setUserSongs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { handleFollow, handleUnfollow } = useFollowUnfollow();
  const [isFollowing, setIsFollowing] = useState(false);

  const [isProfileEditModalOpen, setIsProfileEditModalOpen] = useState(false);
  const [isPostEditModalOpen, setIsPostEditModalOpen] = useState(false);
  const [postToEdit, setPostToEdit] = useState(null);
  const [activeTab, setActiveTab] = useState('Info');
  const [isLikedSongsLoading, setIsLikedSongsLoading] = useState(true);

  const [isFollowLoading, setIsFollowLoading] = useState(false);

  const { userId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const isCurrentUser = currentUser && currentUser.uid === userId;

  const menuOptions1 = [
    {
      label: 'Logout',
      handler: () => {
        auth.signOut().then(() => {
          navigate('/');
        });
      },
    },
  ];

  const fetchFollowers = useCallback(async () => {
    try {
      const followersSnapshot = await getDocs(
        collection(db, 'users', userId, 'followers')
      );
      const followersList = await Promise.all(
        followersSnapshot.docs.map(async (document) => {
          const uid = document.data().userId;
          const userDoc = await getDoc(doc(db, 'users', uid));
          return {
            uid,
            displayName: userDoc.data().displayName,
            avatar: userDoc.data().avatar,
          };
        })
      );
      setFollowers(followersList);
    } catch (error) {
      console.error('Error fetching followers: ', error);
    }
  }, [userId]);

  const fetchFollowing = useCallback(async () => {
    try {
      const followingSnapshot = await getDocs(
        collection(db, 'users', userId, 'following')
      );
      const followingList = await Promise.all(
        followingSnapshot.docs.map(async (document) => {
          const uid = document.data().userId;
          const userDoc = await getDoc(doc(db, 'users', uid));
          return {
            uid,
            displayName: userDoc.data().displayName,
            avatar: userDoc.data().avatar,
          };
        })
      );
      setFollowing(followingList);
    } catch (error) {
      console.error('Error fetching following: ', error);
    }
  }, [userId]);

  const fetchUserDataAndSongs = useCallback(async () => {
    try {
      if (!userId) {
        setIsLoading(false);
        return;
      }

      const userDocRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      } else {
        setIsLoading(false);
        return;
      }

      const songs = await fetchSongsByUser(userId);
      setUserSongs(songs);

      if (currentUser) {
        const followingQuery = query(
          collection(db, 'users', currentUser.uid, 'following'),
          where('userId', '==', userId)
        );
        const followingSnapshot = await getDocs(followingQuery);
        setIsFollowing(!followingSnapshot.empty);
      }

      await fetchFollowers();
      await fetchFollowing();

      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data: ', error);
      setIsLoading(false);
    }
  }, [fetchSongsByUser, userId, currentUser, fetchFollowers, fetchFollowing]);

  useEffect(() => {
    if (userId) {
      fetchUserDataAndSongs();
      const params = new URLSearchParams(location.search);
      const tab = params.get('tab');
      if (tab) {
        setActiveTab(tab);
      } else {
        setActiveTab('Info');
      }
    }
  }, [userId, fetchUserDataAndSongs, location.search]);

  const handleLike = (songId) => {
    if (currentUser && currentUser.uid) {
      likeSong(songId, currentUser.uid);
    }
  };

  const handleUnlike = (songId) => {
    if (currentUser && currentUser.uid) {
      unlikeSong(songId, currentUser.uid);
    }
  };

  const handleFollowAndUpdate = async () => {
    setIsFollowLoading(true);
    await handleFollow();
    setIsFollowing(true);
    await fetchFollowers();
    setIsFollowLoading(false);
  };

  const handleUnfollowAndUpdate = async () => {
    setIsFollowLoading(true);
    await handleUnfollow();
    setIsFollowing(false);
    await fetchFollowers();
    setIsFollowLoading(false);
  };

  const openProfileEditModal = () => {
    setIsProfileEditModalOpen(true);
  };

  const closeProfileEditModal = () => {
    setIsProfileEditModalOpen(false);
  };

  const openPostEditModal = (post) => {
    setPostToEdit(post);
    setIsPostEditModalOpen(true);
  };

  const closePostEditModal = () => {
    setIsPostEditModalOpen(false);
    setPostToEdit(null);
  };

  const convertTextToLinks = (text) => {
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    return text.replace(urlPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  const handleDelete = (songId) => {
    setUserSongs((prevSongs) => prevSongs.filter((song) => song.id !== songId));
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.spinner}></div>
        <p className={styles.loadingText}>
          Here comes {userData?.username || userData?.displayName || '...'}
        </p>
      </div>
    );
  }

  if (!userData) {
    return <div>User not found</div>;
  }

  const defaultAvatarURL = '/avatar.png';

  const handleTabChange = (e, tabName) => {
    e.preventDefault();
    setActiveTab(tabName);
    const params = new URLSearchParams(location.search);
    params.set('tab', tabName);
    navigate({ search: params.toString() });
  };

  const validateAndFormatUrl = (url) => {
    if (!url) return '';
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  return (
    <>
      <Helmet>
        <title>
          {userData?.username || userData?.displayName || 'User'} | Good Song
          Club
        </title>
        <meta
          name="description"
          content={`${
            userData?.username || userData?.displayName || 'User'
          }'s profile on Good Song Club`}
        />
        <meta
          name="og:title"
          content={userData?.username || userData?.displayName || 'User'}
        />
        <meta
          name="og:description"
          content={`${
            userData?.username || userData?.displayName || 'User'
          }'s profile on Good Song Club`}
        />
        <meta name="og:image" content={userData?.avatar || defaultAvatarURL} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={userData?.username || userData?.displayName || 'User'}
        />
        <meta
          name="twitter:description"
          content={`${
            userData?.username || userData?.displayName || 'User'
          }'s profile on Good Song Club`}
        />
        <meta
          name="twitter:image"
          content={userData?.avatar || defaultAvatarURL}
        />
      </Helmet>
      <div className={styles.container}>
        <div className={styles.userPageHeader}>
          <div className={styles.userPageHeaderInner}>
            <div className={styles.sidePanelHeadUserCluster}>
              <div
                className={styles.mainAvatar}
                style={{
                  backgroundImage: `url(${userData?.avatar || defaultAvatarURL})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                }}
                title={userData?.displayName || 'User'}
              />
              <div className={styles.mainUserInfo}>
                <h3 className={styles.mainUserName}>
                  {userData?.username || userData?.displayName || 'User'}
                </h3>
                <p className={styles.mainSubheader}>
                  {userSongs.length} songs shared{' '}
                </p>
              </div>
            </div>
            <div className={styles.sidePanelHeaderButtons}>
              {isCurrentUser ? (
                <div className={styles.headerButtons}>
                  <button
                    onClick={openProfileEditModal}
                    className={styles.sidePanelHeaderButton}
                  >
                    <Edit fill="#404040" />
                  </button>
                  <OverflowMenu options={menuOptions1} />
                </div>
              ) : isFollowing ? (
                <button
                  onClick={handleUnfollowAndUpdate}
                  className={styles.unfollowButton}
                  disabled={isFollowLoading}
                >
                  {isFollowLoading ? (
                    <div className={styles.spinner}></div>
                  ) : (
                    '❤️ Following'
                  )}
                </button>
              ) : (
                <button
                  onClick={handleFollowAndUpdate}
                  disabled={isFollowLoading}
                  className={styles.followButton}
                >
                  {isFollowLoading ? (
                    <div className={styles.spinner}></div>
                  ) : (
                    'Follow'
                  )}
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.bodyPanelHead}>
          <Link
            to="#"
            onClick={(e) => handleTabChange(e, 'Info')}
            className={
              activeTab === 'Info'
                ? `${styles.tab} ${styles.activeTab}`
                : styles.tab
            }
          >
            Info
          </Link>
          <Link
            to="#"
            onClick={(e) => handleTabChange(e, 'Shared songs')}
            className={
              activeTab === 'Shared songs'
                ? `${styles.tab} ${styles.activeTab}`
                : styles.tab
            }
          >
            Shared songs
          </Link>
          <Link
            to="#"
            onClick={(e) => handleTabChange(e, 'Following')}
            className={
              activeTab === 'Following'
                ? `${styles.tab} ${styles.activeTab}`
                : styles.tab
            }
          >
            Following&nbsp;&nbsp;{following.length}
          </Link>
          <Link
            to="#"
            onClick={(e) => handleTabChange(e, 'Followers')}
            className={
              activeTab === 'Followers'
                ? `${styles.tab} ${styles.activeTab}`
                : styles.tab
            }
          >
            Followers&nbsp;&nbsp;{followers.length}
          </Link>
          <Link
            to="#"
            onClick={(e) => handleTabChange(e, 'Likes')}
            className={
              activeTab === 'Likes'
                ? `${styles.tab} ${styles.activeTab}`
                : styles.tab
            }
          >
            Likes
          </Link>
        </div>
        <div className={styles.bodyPanel}>
          <div className={styles.contentBox}>
            {activeTab === 'Info' && (
              <div className={styles.userDetails}>
                <div
                  className={styles.avatar}
                  style={{
                    backgroundImage: `url(${userData?.avatar || defaultAvatarURL})`,
                  }}
                  title={userData?.displayName || 'User'}
                >
                  <h1>
                    {userData?.username || userData?.displayName || 'User'}
                  </h1>
                </div>
                {userData.createdAt && (
                  <p>
                    Member since{' '}
                    {userData.createdAt.toDate().toLocaleDateString()}
                  </p>
                )}
                {userData.website ||
                userData.instagram ||
                userData.twitter ||
                userData.bio ? (
                  <>
                    {userData.bio && (
                      <div className={styles.entry}>
                        <p className={styles.entryTitle}>Bio:</p>
                        <p
                          className={styles.entryContent}
                          dangerouslySetInnerHTML={{
                            __html: convertTextToLinks(userData.bio).replace(
                              /\n/g,
                              '<br/>'
                            ),
                          }}
                        />
                      </div>
                    )}
                    {userData.website && (
                      <div className={styles.entry}>
                        <p className={styles.entryTitle}>Website:</p>
                        <p className={styles.entryContent}>
                          <a
                            href={validateAndFormatUrl(userData.website)}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {userData.website}
                          </a>
                        </p>
                      </div>
                    )}
                    {userData.instagram && (
                      <div className={styles.entry}>
                        <p className={styles.entryTitle}>Instagram:</p>
                        <p className={styles.entryContent}>
                          <a
                            href={`https://instagram.com/${userData.instagram}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            @{userData.instagram}
                          </a>
                        </p>
                      </div>
                    )}
                    {userData.twitter && (
                      <div className={styles.entry}>
                        <p className={styles.entryTitle}>Twitter:</p>
                        <p className={styles.entryContent}>
                          <a
                            href={`https://twitter.com/${userData.twitter}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            @{userData.twitter}
                          </a>
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <p className={styles.emptyState}>
                    {userData?.username || userData?.displayName || 'User'} has
                    not added any information yet.
                  </p>
                )}
              </div>
            )}

            {activeTab === 'Shared songs' &&
              userSongs
                .filter((song) => song.timestamp) // Ensure the song has a timestamp
                .sort(
                  (a, b) =>
                    b.timestamp.seconds - a.timestamp.seconds ||
                    b.timestamp.nanoseconds - a.timestamp.nanoseconds
                )
                .map((song) => (
                  <UserPageSong
                    key={song.id} // Ensure song.id is unique
                    song={song}
                    currentUser={currentUser}
                    onLike={handleLike}
                    onUnlike={handleUnlike}
                    openSongPage={openSongPage}
                    openEditModal={openPostEditModal}
                    onDelete={handleDelete}
                  />
                ))}

            {activeTab === 'Following' &&
              (following.length > 0 ? (
                following.map((user, index) => (
                  <div className={styles.followUser} key={user?.uid || index}>
                    {currentUser && <UserListItem user={user} />}
                  </div>
                ))
              ) : (
                <p className={styles.emptyState}>
                  {userData?.username || userData?.displayName || 'User'} is not
                  following anyone yet.
                </p>
              ))}

            {activeTab === 'Followers' &&
              (followers.length > 0 ? (
                followers.map((user, index) => (
                  <div key={user?.userId || index}>
                    {currentUser && <UserListItem user={user} />}
                  </div>
                ))
              ) : (
                <p className={styles.emptyState}>
                  {userData?.username || userData?.displayName || 'User'} has no
                  followers yet.
                </p>
              ))}

            {activeTab === 'Likes' && (
              <LikedSongsList userId={userId} openSongPage={openSongPage} />
            )}
          </div>
        </div>
      </div>

      <>
        <EditProfile
          show={isProfileEditModalOpen}
          onClose={closeProfileEditModal}
          userId={userId}
          fetchUserDataAndSongs={fetchUserDataAndSongs}
          currentUserDisplayName={currentUser?.displayName || ''}
          currentAvatar={userData?.avatar}
        />
        <PostFormModal
          show={isPostEditModalOpen}
          onClose={closePostEditModal}
          isEditing={true}
          postToEdit={postToEdit}
        />
      </>
    </>
  );
};

export default UserPage;
