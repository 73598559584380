import React from 'react';
import styles from './Tos.module.scss';

const Tos = () => {
  return (
    <div className={styles.tosContainer}>
      <h1 className={styles.title}>Terms of Service for Good Song Club</h1>
      <p className={styles.effectiveDate}>
        <strong>Effective Date:</strong> 10th February 2025
      </p>
      <p className={styles.intro}>
        Welcome to <strong>Good Song Club</strong> ("Service" or "we/us"). By
        accessing or using https://goodsong.club or our services, you agree to
        these Terms of Service ("Terms"). Please read them carefully.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>1. Acceptance of Terms</h2>
      <p className={styles.sectionContent}>
        By using our Service, you agree to these Terms and our Privacy Policy.
        If you do not agree, you may not use the Service.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>2. Eligibility</h2>
      <p className={styles.sectionContent}>
        To use Good Song Club, you must meet the following conditions:
      </p>
      <ul className={styles.tosList}>
        <li className={styles.listItem}>
          You are at least <strong>13 years old</strong>, which complies with
          U.S. Children's Online Privacy Protection Act (COPPA).
        </li>
        <li className={styles.listItem}>
          In certain jurisdictions, additional age restrictions may apply. If
          you are under <strong>18</strong>, you may require parental or
          guardian consent depending on local regulations.
        </li>
      </ul>
      <p className={styles.note}>
        <em>Note:</em> Some platforms set age restrictions based on content. For
        apps involving user-generated content, a common threshold is{' '}
        <strong>13 years</strong> under COPPA, while other social apps opt for{' '}
        <strong>16 or 18</strong> to address privacy and liability concerns.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>3. Account Responsibilities</h2>
      <p className={styles.sectionContent}>
        You are responsible for keeping your account secure. You must not share
        your login details. Notify us immediately if you suspect unauthorized
        activity.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>4. User-Generated Content</h2>
      <p className={styles.sectionContent}>
        Good Song Club allows users to post content, including song links (via
        YouTube), comments, profile bios, and avatar images. By posting content,
        you grant us permission to display it on the platform.
      </p>
      <p className={styles.sectionContent}>
        You retain ownership of your content. We will not sell, share, or
        distribute your data or content without your explicit consent.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>5. Data Usage and Privacy</h2>
      <p className={styles.sectionContent}>
        We value your privacy. Good Song Club does <strong>not</strong> sell
        your personal data. Our use of your data is limited to improving the
        platform experience and providing core features. See our{' '}
        <strong>Privacy Policy</strong> for more details.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>6. Prohibited Activities</h2>
      <p className={styles.sectionContent}>You agree not to:</p>
      <ul className={styles.tosList}>
        <li className={styles.listItem}>
          Post inappropriate, offensive, or illegal content.
        </li>
        <li className={styles.listItem}>
          Use the Service to harass, intimidate, or harm others.
        </li>
        <li className={styles.listItem}>
          Misuse the platform by posting copyrighted material without permission
          (e.g., videos or music not hosted on YouTube).
        </li>
      </ul>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>7. Copyright Policy</h2>
      <p className={styles.sectionContent}>
        Good Song Club only supports linking to publicly available YouTube
        content. You are responsible for ensuring that any external content you
        post complies with copyright laws.
      </p>
      <p className={styles.sectionContent}>
        If you believe content on our platform infringes on your copyright,
        please contact us at [Insert contact email].
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>8. Limitation of Liability</h2>
      <p className={styles.sectionContent}>
        To the maximum extent permitted by law, Good Song Club is not liable for
        any damages or losses resulting from your use of the Service.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>9. Termination</h2>
      <p className={styles.sectionContent}>
        We may suspend or terminate your account at any time if you violate
        these Terms.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>10. Modifications to the Service</h2>
      <p className={styles.sectionContent}>
        We may update the Service or these Terms at any time. Continued use of
        Good Song Club after changes constitutes your acceptance of the updated
        Terms.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>11. Governing Law</h2>
      <p className={styles.sectionContent}>
        These Terms are governed by the applicable laws of the country or region
        in which you reside, without regard to conflict of law principles.
      </p>
      <hr className={styles.divider} />
      <h2 className={styles.sectionTitle}>12. Contact Us</h2>
      <p className={styles.sectionContent}>
        For questions or concerns about these Terms, please contact us at{' '}
        <strong>hello@goodsong.club</strong>.
      </p>
    </div>
  );
};

export default Tos;
