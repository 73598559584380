import React from 'react';

const Plus = ({ fill = '#404040' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 4H11V11H4V13H11V20H13V13H20V11H13V4Z" fill={fill} />
  </svg>
);

export default Plus;
