import React from 'react';

const External = ({ fill = '#404040' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M11.707 6V8H15.293L6 17.293L7.41406 18.707L16.707 9.41406V13H18.707V6H11.707Z"
        fill={fill}
      />
    </svg>
  );
};

export default External;
